var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{attrs:{"id":"container"}},[_c('el-header',{staticClass:"header"},[_c('div',{staticClass:"logo"},[_vm._v("CMS")])]),_c('el-container',[_c('el-aside',{staticClass:"side",attrs:{"width":"200px"}},[_c('el-menu',[_vm._l((_vm.$router.options.routes),function(item,index){return [(
              !item.meta.hide && item.meta.auth && _vm.isMenuShow(item.meta.auth)
            )?_c('el-submenu',{key:index,attrs:{"index":index + ''}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-s-tools"}),_vm._v(" "+_vm._s(item.meta.title)+" ")]),_vm._l((item.children),function(child){return _c('div',{key:child.path},[(
                  !child.meta.hide &&
                    item.meta.auth &&
                    _vm.isMenuShow(item.meta.auth)
                )?_c('el-menu-item',{attrs:{"index":child.name + ''},on:{"click":function($event){_vm.$router
                    .push(item.path + '/' + child.path)
                    .catch(function (err) {})}}},[_vm._v(_vm._s(child.meta.title)+" ")]):_vm._e()],1)})],2):_vm._e()]})],2)],1),_c('el-main',{staticClass:"main"},[_c('router-view',{key:_vm.key})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }