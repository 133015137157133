import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

export const loginApi = {
  quickLogin: (phone, code, pass) => {
    return ajax.post(
      `${base}/api/itedu/v1/quicklogin?phone=${phone}&code=${code}&pass=${pass}`
    );
  },
  getSmsCode: (phone) => {
    return ajax.post(`${base}/api/itedu/v1/smscode?phone=${phone}`);
  },

  authList: () => {
    return ajax.get(`${base}/api/itedu/v1/auth`);
  },
  getUser: () => {
    return ajax.get(`${base}/api/itedu/v1/user`);
  },
  logout: () => {
    return ajax.get(`${base}/api/itedu/v1/logout`);
  },
};

export const routerAuth = {
  "/course": ["course-r", "course-w", "course-d", "root"],
  "/teach": ["teach-r", "teach-w", "teach-d", "root"],
  "/practice": ["practice-r", "practice-w", "practice-d", "root"],
  "/article": ["articel-r", "article-w", "article-d", "root"],
  "/ticket": ["ticket-r", "ticket-w", "ticket-d", "root"],
  "/order": ["order-r", "order-w", "order-d", "root"],
  "/cos": ["cos-r", "cos-w", "cos-d", "root", "pub-r", "pub-w", "pub-d"],
  "/vod": ["vod-r", "vod-w", "vod-d", "root"],
  "/qrcode": ["operation-r", "operation-w", "operation-d", "root"],
  "/market-recite": ["operation-r", "operation-w", "operation-d", "root"],
};
