import Vue from "vue"; // （1）框架 = 数据双向绑定、页面的生命周期、路由
import App from "./App.vue";
import router from "./router"; // (3) 路由
import ElementUI from "element-ui"; // (2) UI框架
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI); // (2)

// router.beforeEach((to, from, next) => {
//   //NProgress.start();
//   if (to.path == "/login") {
//     localStorage.clear();
//   }
//   // let user = JSON.parse(sessionStorage.getItem('user'));
//   let userCookie = JSON.parse(localStorage.getItem("user"));
//   if (!userCookie && to.path != "/login") {
//     next({ path: "/login" });
//   } else {
//     next();
//   }
// });
//const tm = require("markdown-it-texmath");
const MarkdownIt = require("markdown-it")({});

// .use(tm, {
//   engine: require("katex"),
//   delimiters: "dollars",
//   katexOptions: { macros: { "\\RR": "\\mathbb{R}" } },
// });

Vue.prototype.$TcPlayer = window.TCPlayer;
Vue.prototype.$DogePlayer = window.DogePlayer;
Vue.prototype.$markdownIt = MarkdownIt;
Vue.prototype.$eventBus = new Vue();

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
