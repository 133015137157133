let tikuRouter = {
  path: "/tiku",
  name: "tiku",
  meta: {
    hide: false,
    title: "题库",
    auth: ["root", "practice-r", "practice-w", "practice-d"],
  },
  component: () => import("@/views/Home.vue"),
  children: [
    {
      path: "aida-tiku-choice-add",
      name: "aida-tiku-choice-add",
      meta: {
        hide: false,
        title: "添加选择题",
        auth: ["root", "practice-w", "practice-d"],
      },
      component: () => import("@/views/tiku/choiceQuestionAdd.vue"),
    },
    {
      path: "aida-tiku-blank-add",
      name: "aida-tiku-blank-add",
      meta: {
        hide: false,
        title: "添加主观题",
        auth: ["root", "practice-w", "practice-d"],
      },
      component: () => import("@/views/tiku/blankQuestionAdd.vue"),
    },
    {
      path: "aida-tiku-question-search",
      name: "aida-tiku-question-search",
      meta: {
        hide: false,
        title: "题目搜索",
        auth: ["root", "practice-r"],
      },
      component: () => import("@/views/tiku/aidaTikuQuestionSearch.vue"),
    },
    {
      path: "aida-tiku-question-view",
      name: "aida-tiku-question-view",
      meta: {
        hide: true,
        title: "题目预览",
        auth: ["root", "practice-r"],
      },
      component: () => import("@/views/tiku/aidaTikuQuestionView.vue"),
    },
    {
      path: "plain-text-question-view",
      name: "plain-text-question-view",
      meta: {
        hide: true,
        title: "题目预览",
        auth: ["root", "practice-r"],
      },
      component: () => import("@/views/tiku/plainTextQuestionView.vue"),
    },
    {
      path: "keypoint",
      name: "keypoint",
      meta: {
        hide: false,
        title: "考点",
        auth: ["root", "practice-w", "practice-d", "practice-r"],
      },
      component: () => import("@/views/tiku/keypoint/keypoint.vue"),
    },
    {
      path: "aida-tiku-material-add",
      name: "aida-tiku-material-add",
      meta: {
        hide: false,
        title: "新增材料",
        auth: ["root", "practice-w", "practice-d", "practice-r"],
      },
      component: () => import("@/views/tiku/materialAdd.vue"),
    },
    {
      path: "aida-tiku-material-list",
      name: "aida-tiku-material-list",
      meta: {
        hide: false,
        title: "材料查询",
        auth: ["root", "practice-w", "practice-d", "practice-r"],
      },
      component: () => import("@/views/tiku/materialList.vue"),
    },
    {
      path: "aida-tiku-material-view",
      name: "aida-tiku-material-view",
      meta: {
        hide: true,
        title: "预览材料",
        auth: ["root", "practice-w", "practice-d", "practice-r"],
      },
      component: () => import("@/views/tiku/materialView.vue"),
    },
    {
      path: "aida-tiku-paper-add",
      name: "aida-tiku-paper-add",
      meta: {
        hide: false,
        title: "新增试卷",
        auth: ["root", "practice-w", "practice-d", "practice-r"],
      },
      component: () => import("@/views/tiku/paperAdd.vue"),
    },
    {
      path: "aida-tiku-paper-list",
      name: "aida-tiku-paper-list",
      meta: {
        hide: false,
        title: "试卷查询",
        auth: ["root", "practice-w", "practice-d", "practice-r"],
      },
      component: () => import("@/views/tiku/paperList.vue"),
    },
    {
      path: "aida-tiku-paper-view",
      name: "aida-tiku-paper-view",
      meta: {
        hide: true,
        title: "新增试卷",
        auth: ["root", "practice-w", "practice-d", "practice-r"],
      },
      component: () => import("@/views/tiku/paperView.vue"),
    },
    {
      path: "aida-tiku-exercise-book-add",
      name: "aida-tiku-exercise-book-add",
      meta: {
        hide: false,
        title: "新增练习册",
        auth: ["root", "practice-w", "practice-d", "practice-r"],
      },
      component: () => import("@/views/tiku/exerciseBookAdd.vue"),
    },
    {
      path: "aida-tiku-exercise-book-list",
      name: "aida-tiku-exercise-book-list",
      meta: {
        hide: false,
        title: "练习册查询",
        auth: ["root", "practice-w", "practice-d", "practice-r"],
      },
      component: () => import("@/views/tiku/exerciseBookList.vue"),
    },
    {
      path: "tiku-simple-add-edit",
      name: "tiku-simple-add-edit",
      meta: {
        hide: false,
        title: "简单题库添加",
        auth: ["root", "practice-w", "practice-d", "practice-r"],
      },
      component: () => import("@/views/tiku/simpleTikuAddEdit.vue"),
    },
    {
      path: "tiku-simple-list",
      name: "tiku-simple-list",
      meta: {
        hide: false,
        title: "简单题库查询",
        auth: ["root", "practice-w", "practice-d", "practice-r"],
      },
      component: () => import("@/views/tiku/simpleTikuList.vue"),
    },
    {
      path: "tiku-backfill",
      name: "tiku-backfill",
      meta: {
        hide: false,
        title: "题库考点修复",
        auth: ["root", "practice-w", "practice-d", "practice-r"],
      },
      component: () => import("@/views/tiku/aidaTikuQuestionByFbPaperId.vue"),
    },
  ],
};

export default tikuRouter;
