/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue"; // (1) 引入的组件
import cosRouter from "./cosRouter.js";
import flexQRCodeRouter from "./flexQRCodeRouter.js";
import reciteRouter from "./reciteRouter.js";
import orderRouter from "./orderRouter.js";
import practiceRouter from "./practiceRouter.js";
import ticketRouter from "./ticketRouter.js";
import vodRouter from "./vodRouter.js";
import liveRouter from "./liveRouter.js";
import teachRouter from "./teachRouter.js";
import courseRouter from "./courseRouter.js";
import termRouter from "./termRouter.js";
import tikuRouter from "./tikuRouter.js";
import crawlerRouter from "./crawlerRouter.js";
import userLearnPlanRouter from "./userLearnPlanRouter.js";
import userHomeWorkRouter from "./userHomeWorkRouter.js";
import csConfigRouter from "./csConfigRouter.js";
import labRouter from "./labRouter.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    meta: {
      hide: true,
      title: "首页",
    },
    redirect: { name: "home-dashboard" },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      hide: true,
      title: "login",
    },
  },
  {
    path: "",
    name: "home",
    component: Home, // (2) 该路由使用的组件
    meta: {
      hide: false,
      title: "首页",
      auth: ["any"],
    },
    children: [
      {
        path: "home-dashboard",
        name: "home-dashboard",
        meta: {
          hide: false,
          title: "首页面板",
          auth: ["any"],
        },
        component: () => import("@/views/dashboard.vue"), // (3) child的组件 基于 父组件
      },
    ],
  },
  //courseRouter,
  //teachRouter,
  //practiceRouter,
  //ticketRouter,
  //orderRouter,
  //cosRouter,
  //vodRouter,
  //liveRouter,
  //flexQRCodeRouter,
  //reciteRouter,
  //termRouter,
  tikuRouter,
  //userLearnPlanRouter,
  //userHomeWorkRouter,
  crawlerRouter,
  //csConfigRouter,
  labRouter,
];

const router = new VueRouter({
  mode: "history",
  //base: "tiku-jam-cms-page",
  routes,
});

// 权限路由守卫
router.beforeEach((to, from, next) => {
  if (to.path == "/login") {
    localStorage.clear();
  }
  let user = localStorage.getItem("user");
  if (!user && to.path != "/login") {
    next({ path: "/login" });
  } else {
    next();
  }
});

export default router;
