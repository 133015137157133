<template>
  <el-container id="container">
    <el-header class="header">
      <div class="logo">CMS</div>
    </el-header>
    <el-container>
      <el-aside width="200px" class="side">
        <el-menu>
          <template v-for="(item, index) in $router.options.routes">
            <el-submenu
              :index="index + ''"
              :key="index"
              v-if="
                !item.meta.hide && item.meta.auth && isMenuShow(item.meta.auth)
              "
            >
              <template slot="title">
                <i class="el-icon-s-tools"></i>
                {{ item.meta.title }}
              </template>
              <div v-for="child in item.children" :key="child.path">
                <el-menu-item
                  v-if="
                    !child.meta.hide &&
                      item.meta.auth &&
                      isMenuShow(item.meta.auth)
                  "
                  :index="child.name + ''"
                  @click="
                    $router
                      .push(item.path + '/' + child.path)
                      .catch((err) => {})
                  "
                  >{{ child.meta.title }}
                </el-menu-item>
              </div>
            </el-submenu>
          </template>
        </el-menu>
      </el-aside>
      <el-main class="main">
        <router-view :key="key" />
        <!--  子组件会渲染在这里, 路由里指定 -->
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import { test, unAuth } from "@/util/http.js";
import { loginApi } from "@/api/loginApi.js";
export default {
  name: "Home",
  data() {
    return {
      collapsed: false,
      userAuth: [],
    };
  },
  methods: {
    isMenuShow(needAuth) {
      let isAny = needAuth.filter((x) => x == "any").length > 0;
      let interSet = needAuth.filter((x) => this.userAuth.includes(x));
      const ans = (interSet && interSet.length > 0) || isAny;
      return ans;
    },
  },
  mounted() {
    loginApi.authList().then((ret) => {
      this.userAuth = ret.data;
      this.$nextTick(() => {});
    });
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
};
</script>

<style lang="less">
.ant-menu-item-group {
  .ant-menu-item-group-title {
    text-align: left;
    padding: 0 0 0 40px;
  }
}
</style>

<style lang="less" scoped>
#container {
  width: 100vw;
  height: 100vh;
  .header {
    display: flex;
    align-items: left;
    justify-content: left;
    background: rgb(62, 79, 178);
    .logo {
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .side {
    .el-submenu {
      text-align: left;
    }
  }
  .main {
    display: flex;
    justify-content: left;
    align-items: left;
  }
}
</style>

<style>
.el-form-item__content {
  /* display: flex;
  flex-direction: row;
  justify-content: left; */
  text-align: left;
}
</style>
