let crawlerRouter = {
  path: "/crawler",
  name: "crawler",
  meta: {
    hide: false,
    title: "第三方抓取",
    auth: ["root", "crawler-w", "crawler-d", "crawler-r"],
  },
  component: () => import("@/views/Home.vue"),
  children: [
    {
      path: "crawler-fb-cookie",
      name: "crawler-fb-cookie",
      meta: {
        hide: false,
        title: "fb cookie",
        auth: ["root", "crawler-w", "crawler-d", "crawler-r"],
      },
      component: () => import("@/views/crawler/fbCookie.vue"),
    },
    {
      path: "crawler-fb-paper-meta-list",
      name: "crawler-fb-paper-meta-list",
      meta: {
        hide: false,
        title: "真题列表",
        auth: ["root", "crawler-w", "crawler-d", "crawler-r"],
      },
      component: () => import("@/views/crawler/fbPaperMetaList.vue"),
    },
    {
      path: "crawler-fb-real-paper",
      name: "crawler-fb-real-paper",
      meta: {
        hide: false,
        title: "fb真题抓取",
        auth: ["root", "crawler-w", "crawler-d", "crawler-r"],
      },
      component: () => import("@/views/crawler/fbRealPaper.vue"),
    },
    // {
    //   path: "crawler-fb-question-list",
    //   name: "crawler-fb-question-list",
    //   meta: {
    //     hide: false,
    //     title: "fb真题题目列表",
    //     auth: ["root", "crawler-w", "crawler-d", "crawler-r"],
    //   },
    //   component: () => import("@/views/crawler/fbQuestionList.vue"),
    // },
    {
      path: "crawler-fb-jzInterview",
      name: "crawler-fb-jzInterview",
      meta: {
        hide: false,
        title: "fb教资面试",
        auth: ["root", "crawler-w", "crawler-d", "crawler-r"],
      },
      component: () => import("@/views/crawler/fbJzInterview.vue"),
    },
    {
      path: "crawler-fb-shenlun-paper",
      name: "crawler-fb-shenlun-paper",
      meta: {
        hide: false,
        title: "fb申论真题",
        auth: ["root", "crawler-w", "crawler-d", "crawler-r"],
      },
      component: () => import("@/views/crawler/fbShenlunPaper.vue"),
    },
  ],
};

export default crawlerRouter;
