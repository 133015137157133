// let vodRouter = {
//   path: "/",
//   component: Home,
//   name: "VOD资源",
//   iconCls: "el-icon-message", //图标样式class
//   prefix: "/vod",
//   children: [
//     {
//       path: "/vod/view/:fileId",
//       component: VodView,
//       name: "VOD预览",
//       hidden: true,
//     },
//     { path: "/vod/list", component: vodList, name: "VOD列表" },
//     { path: "/vod", component: vodAdd, name: "添加VOD资源" },
//   ],
// };

let labRouter = {
  path: "/lab",
  name: "lab",
  meta: {
    hide: false,
    title: "实验室",
    auth: ["vod-r", "vod-w", "vod-d", "root"],
  },
  component: () => import("@/views/Home.vue"),
  children: [
    {
      path: "nplayer",
      name: "nplayer",
      meta: {
        hide: false,
        title: "nplayer",
        auth: ["vod-r", "vod-w", "vod-d", "root"],
      },
      component: () => import("@/views/lab/nplayerView"),
    },
    {
      path: "chatGPT",
      name: "chatGPT",
      meta: {
        hide: false,
        title: "chatGPT",
        auth: ["vod-r", "vod-w", "vod-d", "root"],
      },
      component: () => import("@/views/lab/chatGPT.vue"),
    },
    {
      path: "upload",
      name: "lab-upload",
      meta: {
        hide: false,
        title: "upload",
        auth: ["cos-r", "cos-w", "cos-d", "root"],
      },
      component: () => import("@/views/lab/uploadView.vue"),
    },
    {
      path: "invoice-str",
      name: "invoice-str",
      meta: {
        hide: false,
        title: "invoice-str",
        auth: ["cos-r", "cos-w", "cos-d", "root"],
      },
      component: () => import("@/views/lab/invoiceTool.vue"),
    },
  ],
};

export default labRouter;
